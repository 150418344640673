nav.main {
  padding: 0 30px 30px 0;
}

.main ul {
  list-style-type: none;
}

nav.main a {
  display: block;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  transition: 0.2s;
}

nav.main a:hover {
  transform: translate(20px);
}

nav.main a.active {
  border-left: 10px grey solid;
  transform: translate(0);
}

nav.main li:nth-child(1) a.active {
  border-left-color: green;
}

nav.main li:nth-child(2) a.active {
  border-left-color: blue;
}

nav.main li:nth-child(3) a.active {
  border-left-color: orange;
}

nav.main li:nth-child(4) a.active {
  border-left-color: red;
}
