* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-family: "opens sans", sans-serif;
  font-size: 20px;
}
