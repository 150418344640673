* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-family: "opens sans", sans-serif;
  font-size: 20px;
}

div.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 90%;
  margin: 0 auto;
  color: #222;
}

header {
  flex-basis: 30vh;
}

main {
  display: flex;
  flex-grow: 1;
  padding: 30px 0;
}

aside {
  min-width: 240px;
}

section.page {
  flex-grow: 1;
}

footer {
  background-color: black;
  color: white;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
}

header {
  position: relative;
  overflow: hidden;
}

header img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}

.products h2 {
  margin-bottom: 20px;
}

.products ul {
  list-style: square;
  padding-left: 30px;
}

.products li {
  margin-bottom: 20px;
}

.products a {
  text-decoration: none;
  color: #333;
}

.products a:hover {
  text-decoration: underline;
  color: black;
}

.contact textarea {
  font-size: 16px;
  width: 80%;
  min-height: 200px;
  margin: 20px 0;
  line-height: 1.6;
}

.contact button {
  display: block;
  margin: 0 20% 0 auto;
  border: 2px solid black;
  background-color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.contact button:hover {
  color: yellow;
  background-color: #000;
}

nav.main {
  padding: 0 30px 30px 0;
}

.main ul {
  list-style-type: none;
}

nav.main a {
  display: block;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

nav.main a:hover {
  -webkit-transform: translate(20px);
          transform: translate(20px);
}

nav.main a.active {
  border-left: 10px grey solid;
  -webkit-transform: translate(0);
          transform: translate(0);
}

nav.main li:nth-child(1) a.active {
  border-left-color: green;
}

nav.main li:nth-child(2) a.active {
  border-left-color: blue;
}

nav.main li:nth-child(3) a.active {
  border-left-color: orange;
}

nav.main li:nth-child(4) a.active {
  border-left-color: red;
}

footer span {
  color: red;
}

footer p {
  font-size: 14px;
}

